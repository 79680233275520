<template>
  <div>
    <div>
      <div>
        <router-link :to="{ name: 'Details' }" class="text-gray-500 uppercase font-bold tracking-wide text-sm inline-flex items-center justify-center focus:outline-none focus:underline hover:text-gray-800 transition duration-150 ease-in-out">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="arrow-narrow-left w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
          <span class="ml-2">back</span>
        </router-link>
      </div>
      <h2 class="text-4xl text-blue-800 font-medium">Create guest profile</h2>
    </div>

    <div class="max-w-lg w-full mt-12 bg-white py-8 px-13 rounded-lg shadow-md">
      <ValidationObserver ref="guestForm" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(createGuest)">
          <div class="grid grid-cols-1 row-gap-6">
            <div>
              <label for="name" class="register-label">Your Name</label>
              <ValidationProvider mode="lazy" rules="required|max:255" name="Guest name" vid="name" v-slot="{ errors }">
                <div>
                  <input name="name" id="name" type="text" class="guestio-form-input w-full" v-model="guestForm.name" />
                </div>
                <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div>
              <label for="bio" class="register-label">Your Bio</label>
              <ValidationProvider mode="lazy" rules="required" name="Bio" vid="bio" v-slot="{ errors }">
                <div>
                  <textarea-autosize class="shadow-sm guestio-form-textarea w-full" name="bio" id="bio" v-model="guestForm.bio" :min-height="200" />
                </div>
                <p class="text-red-500 text-sm font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div>
              <label for="bio" class="register-label">Short description</label>
              <ValidationProvider rules="required|max:25" name="Description" vid="title" v-slot="{ errors }">
                <TextInput v-model="guestForm.title" :errors="errors" placeholder="e.g. Nobel Prize winner"/>
              </ValidationProvider>
            </div>

            <div>
              <label class="register-label">Your expertise</label>
              <TagSelect @selected="onTagSelected"/>
            </div>

            <div>
              <label class="register-label">Categories</label>
              <CategorySelect @selected="onCategorySelected"/>
            </div>

            <div>
              <label class="register-label">Your Image</label>
              <ValidationProvider mode="lazy" name="Image" vid="imageUrl" v-slot="{ errors }" class="flex">
                <MediaUploadSingle
                  entity="new-guest"
                  :imageUrl="guestForm.imageUrl"
                  @uploaded="uploadedImage"
                  @deleted="deletedImage"
                />
                <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <Links :links="guestForm.links"/>

            <div class="flex items-center justify-end">
              <router-link :to="{ name: 'Details' }" class="text-pink-500 hover:text-pink-700">Cancel</router-link>
              <button type="submit" class="ml-4 h-12 btn disabled:opacity-50 flex items-center" :disabled="working">
                <loading-icon v-if="working" class="h-2" />
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import Links from "@/components/register/Links";
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';
  import TextInput from "@/components/shared/TextInput";
  import CategorySelect from '@/components/shared/CategorySelect';
  import TagSelect from '@/components/shared/TagSelect';

  export default {
    components: { Links, MediaUploadSingle, TextInput, CategorySelect, TagSelect },

    data() {
      return {
        working: false,

        guestForm: {
          tags: [],
          categories: [],
          links: {
            social_media: {
              linkedin: '',
              twitter: '',
              instagram: '',
              youtube: '',
              facebook: '',
              wikipedia: '',
              website: '',
              tiktok: '',
            },
            podcasts: [],
            videos: [],
            articles: [],
          },
          imageUrl: ''
        },
      }
    },

    methods: {
      onCategorySelected(categories) {
        if (categories) {
          let categoriesIds = categories.map(c => c.id);
          this.guestForm.categories = categoriesIds
        }
      },

      onTagSelected(tags) {
        if (tags) {
          this.guestForm.tags = tags
        }
      },

      async createGuest() {
        this.working = true

        await this.$store.dispatch('guest/create', this.guestForm)
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.guestForm.setErrors(error.response.data.errors)
            }
          })

        await this.$store.dispatch('auth/getUser')

        this.$router.push({name: 'GuestDetails'})
      },

      uploadedImage(url) {
        this.guestForm.imageUrl = url
      },

      deletedImage() {
        this.guestForm.imageUrl = null
      }
    },
  }
</script>
